/* eslint eqeqeq: "off" */
import React, { useState, useCallback, useEffect } from "react"
import { navigate } from "gatsby"
import Layout, { LayoutHeader } from "../ui/Layout"
import connectStore from "../state/connectStore"
import VisibilityTop from "../ui/VisibilityTop"
import OrderSummaryCard from "../ui/OrderSummaryCard"
import { fetchOrder, pruneInvalidOrders } from "../util/OrderUtil"
import {
  Divider,
  Container,
  Button,
} from "semantic-ui-react"
import Color from "../style/Color"

const onNext = () => navigate("/order-qr-reader", { state: { showBack: true }});
const onSort = (a,b) => {
  if(a.finishedCookingAt == null && b.finishedCookingAt == null) {
    return b.estimatedFinishedCookingAt - a.estimatedFinishedCookingAt;
  } else if(a.finishedCookingAt == null && b.finishedCookingAt != null) {
    return 1;
  } else {
    return -1;
  }
};

const Page = ({ location, order, submittedOrderIds=[], setSubmittedOrderIds }) => {
  const [fixed, setFixed] = useState(false);
  const [submittedOrders, setSubmittedOrders] = useState([]);

  const getNewOrders = useCallback(
    () => submittedOrders.filter(ord => ord.orderId == order.orderId || ord.finishedCookingAt == null || 1000*ord.finishedCookingAt >= Date.now()).sort(onSort),
    [submittedOrders, order.orderId]
  );

  const getOldOrders = useCallback(
    () => submittedOrders.filter(ord => ord.orderId != order.orderId && ord.finishedCookingAt != null && 1000*ord.finishedCookingAt < Date.now()).sort(onSort),
    [submittedOrders, order.orderId]
  );

  const fetchSubmittedOrders = () => {
    fetchOrder({
      orderId: submittedOrderIds.filter(id => id != null).join(","),
      onSuccess: orderOrOrders => {
        const orders = pruneInvalidOrders(Array.isArray(orderOrOrders) ? orderOrOrders : [orderOrOrders]);
        setSubmittedOrders(orders);
        setSubmittedOrderIds({ orderIds: orders.map(ord => ord.orderId) });
      },
      onFailure: () => setSubmittedOrders([]),
    });
  };

  // This fires the first time the component is loaded
  useEffect(fetchSubmittedOrders, []); // eslint-disable-line

  // This fires every n seconds afterwards
  useEffect(() => {
    const timer = setTimeout(() => {
      fetchSubmittedOrders();  
    }, 30 * 1000);
    return () => clearTimeout(timer);
  });

  const newOrders = getNewOrders();
  const oldOrders = getOldOrders();

  const hasNewOrders = newOrders.length > 0;
  const hasOldOrders = oldOrders.length > 0;

  return (
    <Layout location={location} to="/order-complete" showBack={false} validate={false}
      header={fixed || !hasNewOrders ? "Your Past Orders" : ("Your Current Order" + (newOrders.length == 1 ? "" : "s"))}
      footer={<Button content="Scan Another Order" icon="plus" color={Color.accent} size="huge" onClick={onNext}/>}
    >
      { newOrders.length > 0 && <>
        <Container textAlign="center">
          {newOrders.map((ord, i) => (
            <OrderSummaryCard key={"old-"+i} order={ord} compact />
          ))}
        </Container>
        { hasOldOrders && <>
          <Divider hidden/>
          <Divider hidden/>
          <Divider/>
          <Divider hidden/>
        </>}
      </>}

      { oldOrders.length > 0 && <>
        { hasNewOrders && <>
          <VisibilityTop fixed={fixed} setFixed={setFixed} fireOnOffScreen={false} fireOnMount={false} updateOn="events">
            <LayoutHeader fireOnOffScreen={false}>
              Your Past Orders
            </LayoutHeader>
          </VisibilityTop>
        </>}

        <Container textAlign="center">
          {oldOrders.map((ord, i) => (
            <OrderSummaryCard key={"old-"+i} order={ord} compact faded />
          ))}
        </Container>
      </>}

      <Divider hidden/>
      <Divider hidden/>
    </Layout>
  );
};

export default connectStore(Page);
